import React, {
  FC,
  MutableRefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { YouTubeEvent, YouTubeProps } from "react-youtube";
import { Socket } from "socket.io-client";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import {
  handleSyncDB,
  handleVolumeChange,
  onEnd,
  pauseVideo,
} from "@/utils/services/video";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import QueueTabsEnum from "@/utils/enums/queue-tabs.enum";
import { TimerStateEnum } from "@/Components/UI/Timer/Timer";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import PlayerController from "./PlayerController/PlayerController";
import { shareVideo } from "@/utils/api/slackApi";
import { useMessageToast } from "./utils/hooks/useMessageToast";
import CurrentVideo from "./CurrentVideo/CurrentVideo";
import QueueTabs from "./QueueTabs/QueueTabs";

interface QueueProps {
  currentVideo: VideoItem | null;
  opts: YouTubeProps["opts"];
  isPlaying: boolean;
  volume: number;
  videoQueue: VideoItem[];
  recentlyPlayedQueue: VideoItem[];
  userCount: number;
  updateVideoQueue: (newQueue: VideoItem[], isEnd?: boolean) => void;
  handleNext: () => void;
  skipBrokenVideo: () => void;
  setIsPlaying: (value: SetStateAction<boolean>) => void;
  handleLike: (video: VideoItem) => void;
  handleDislike: (video: VideoItem) => void;
  socketRef: MutableRefObject<Socket | null>;
  serverEndpoint: string | undefined;
  playerRef: any;
  endTriggered: boolean;
  setEndTriggered: (value: SetStateAction<boolean>) => void;
  setVolume: (value: SetStateAction<number>) => void;
  setVideoQueue: (value: SetStateAction<VideoItem[]>) => void;
  setIsFavoriteToggled: (value: SetStateAction<boolean>) => void;
  onVideoSelect: (video: VideoItem) => void;
}

const Queue: FC<QueueProps> = ({
  currentVideo,
  opts,
  playerRef,
  isPlaying,
  volume,
  videoQueue,
  recentlyPlayedQueue,
  updateVideoQueue,
  handleNext,
  skipBrokenVideo,
  setIsPlaying,
  handleLike,
  handleDislike,
  userCount,
  socketRef,
  serverEndpoint,
  endTriggered,
  setEndTriggered,
  setVolume,
  setVideoQueue,
  setIsFavoriteToggled,
  onVideoSelect,
}) => {
  const { mode } = useContext(ThemeContext);

  const scrollableParent = useRef<HTMLDivElement | null>(null);

  const [timerState, setTimerState] = useState<TimerStateEnum>(
    TimerStateEnum.STOP
  );

  useMessageToast(currentVideo);

  const [activeTab, setActiveTab] = React.useState<
    | QueueTabsEnum.UP_NEXT
    | QueueTabsEnum.RECENTLY_PLAYED
    | QueueTabsEnum.LEADERBOARD
    | QueueTabsEnum.HISTORY
  >(QueueTabsEnum.UP_NEXT);

  const onReady = (event: YouTubeEvent) => {
    event.target.setVolume(volume);
    handleSyncDB(playerRef, serverEndpoint, socketRef, isPlaying);
    const checkState = () => {
      if (
        event.target.getPlayerState() === -1 &&
        sessionStorage.getItem("isPaused") !== "true"
      ) {
        skipBrokenVideo();
      }
    };

    //Temporarily remove skipping video
    //setTimeout(checkState, 15000);
  };

  const onStateChange = (event: YouTubeEvent) => {
    if (sessionStorage.getItem("isPaused") === "true") {
      pauseVideo(playerRef, setIsPlaying);
    } else if (event.target.getPlayerState() === 1) {
      const volumeFromStorage = sessionStorage.getItem("volume");
      if (volumeFromStorage) {
        handleVolumeChange(parseInt(volumeFromStorage), setVolume, playerRef);
      } else {
        handleVolumeChange(10, setVolume, playerRef);
      }
      setTimerState(TimerStateEnum.START);
      setIsPlaying(true);
    } else if (event.target.getPlayerState() === 2 || -1) {
      setIsPlaying(false);
    }
  };

  React.useEffect(() => {
    return () => {
      setTimerState(TimerStateEnum.STOP);
    };
  }, []);

  return (
    <div
      ref={scrollableParent}
      className={`border ${
        mode === "dark"
          ? "bg-background-bgDark100 border-accent-transparent "
          : "bg-background-bgLight100 border-solid border-accent-gray200 shadow-primary"
      } relative p-6 md:p-8 flex flex-col rounded-md w-full scrollbar-none max-h-[1000px] md:max-h-[750px] xl:max-h-none xl:h-[calc(100vh-140px)]  xl:min-h-[1000px] overflow-y-scroll`}
    >
      {videoQueue.length !== 0 ? (
        <>
          <CurrentVideo
            currentVideo={currentVideo!}
            userCount={userCount}
            timerState={timerState}
            opts={opts}
            playerRef={playerRef}
            onReady={onReady}
            onStateChange={onStateChange}
            onEnd={() =>
              onEnd(
                endTriggered,
                setEndTriggered,
                currentVideo,
                serverEndpoint,
                socketRef
              )
            }
            onSlackClick={() => shareVideo(currentVideo)}
            handleLike={handleLike}
            handleDislike={handleDislike}
          >
            {videoQueue.length > 0 && (
              <PlayerController
                volume={volume}
                setVolume={setVolume}
                isPlaying={isPlaying}
                currentVideo={videoQueue[0]}
                handleNext={handleNext}
                socketRef={socketRef}
                serverEndpoint={serverEndpoint}
                playerRef={playerRef}
                setIsFavoriteToggled={setIsFavoriteToggled}
              />
            )}
          </CurrentVideo>
          <QueueTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            recentlyPlayedQueue={recentlyPlayedQueue}
            videoQueue={videoQueue}
            setVideoQueue={setVideoQueue}
            updateVideoQueue={updateVideoQueue}
            handleLike={handleLike}
            handleDislike={handleDislike}
            onVideoSelect={onVideoSelect}
            serverEndpoint={serverEndpoint}
            socketRef={socketRef}
            playerRef={playerRef}
            scrollableParent={scrollableParent}
          />
        </>
      ) : (
        <Paragraph type={ParagraphTypeEnum.p1_Small}>
          Player is empty, add some videos!
        </Paragraph>
      )}
    </div>
  );
};

export default Queue;
