import { FC, MutableRefObject, useContext, useEffect, useState } from "react";
import { FilterIcon } from "@/assets/svg/svg";
import { UserContext } from "@/context/UserContext/UserContext";
import {
  FavoriteContext,
  GroupsInterface,
} from "@/context/FavoriteContext/FavoriteContext";
import { useFavoriteGroupsContext } from "@/context/FavoriteGroupsContext/utils/hooks/useFavoriteGroupsContext";
import { VideoItem } from "@/utils/types/video-item.type";
import { useLimitResults } from "@/utils/hooks/useLimitResults";
import Loader from "@/Components/UI/Loader/Loader";
import DefaultButton from "@/Components/UI/DefaultButton/DefaultButton";
import Tag from "@/Components/UI/Tag/Tag";
import TagSizeEnum from "@/Components/UI/Tag/utils/enums/tag-size.enum";
import useSetDisplayedVideos from "./utils/hooks/useSetDisplayedVideos";
import FavoriteOptions from "./FavoriteOptions/FavoriteOptions";
import FavoriteContent from "./FavoriteContent/FavoriteContent";
import RiskyAlert from "@/Components/UI/RiskyAlert/RiskyAlert";
import { useRiskyAlert } from "@/utils/hooks/useRiskyAlert";

interface FavoritePlayListProps {
  onVideoSelect: (video: VideoItem) => void;
  scrollableParent?: MutableRefObject<HTMLDivElement | null>;
}

const FavoritePlayList: FC<FavoritePlayListProps> = ({
  onVideoSelect,
  scrollableParent,
}) => {
  const { user, name } = useContext(UserContext)!;
  const { favoriteUserList } = useContext(FavoriteContext)!;
  const { state } = useFavoriteGroupsContext();
  const { isLoading, groups } = state;

  const { isOpen, message, showAlert, closeAlert, onConfirm } = useRiskyAlert();

  const favoriteGroups = groups;

  const [filterByTitle, setFilterByTitle] = useState<boolean>(false);

  const [selectedGroup, setSelectedGroup] = useState<GroupsInterface>({
    id: 1,
    value: "All Videos",
    label: "All Videos",
    videos: [],
  });

  const [selectedVideo, setSelectedVideo] = useState<VideoItem | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { videosToShow, setVideosToShow, reversedVideos, resetVideosList } =
    useSetDisplayedVideos(
      selectedGroup,
      favoriteUserList,
      filterByTitle,
      groups
    );

  const {
    displayedItems,
    isExpanded,
    handleShowMore,
    handleBackToTop,
    handleCollapseAll,
  } = useLimitResults(reversedVideos, 5);

  // Trigger search on searchterm update
  useEffect(() => {
    searchFavorites();
  }, [searchTerm]);

  // Update displayed video list when favorites or group changes
  useEffect(() => {
    resetVideosList();
    setSearchTerm("");
  }, [favoriteUserList, selectedGroup]);

  // Update selected group when groups update
  useEffect(() => {
    if (favoriteGroups && favoriteGroups.length > 0) {
      const updatedGroup = favoriteGroups!.find(
        (group) => group.id === selectedGroup.id
      );
      if (updatedGroup) {
        setSelectedGroup(updatedGroup);
      }
    }
  }, [favoriteGroups, selectedGroup.id]);

  const searchFavorites = () => {
    if (searchTerm.length < 1) {
      resetVideosList();
    } else {
      const upperCaseSearchTerm = searchTerm.toUpperCase();
      const filteredItems = videosToShow.filter((item) => {
        return item.title.toUpperCase().includes(upperCaseSearchTerm);
      });
      setVideosToShow(filteredItems);
    }
  };

  const addAllFavorite = () => {
    if (favoriteUserList && favoriteUserList.length > 0) {
      videosToShow.forEach((video) => {
        onVideoSelect({
          id: video.id,
          title: video.title,
          duration: video.duration,
          ...(user ? { added: name } : {}),
          likes: 0,
          dislikes: 0,
          userReactions: {},
        });
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-6 md:p-8 flex gap-2 flex-col">
          <FavoriteOptions
            onVideoSelect={onVideoSelect}
            videosToShow={videosToShow}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            favoriteUserList={favoriteUserList}
            favoriteGroups={favoriteGroups}
            currentGroup={selectedGroup.label}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleCollapseAll={handleCollapseAll}
          />

          {user && displayedItems.length > 0 && (
            <>
              <DefaultButton
                onClick={() =>
                  showAlert("add multiple songs to the queue", addAllFavorite)
                }
                type="submit"
              >
                {"Add all to queue".toLocaleUpperCase()}
              </DefaultButton>
              <Tag
                label={"Title"}
                icon={<FilterIcon tooltipText="Order by Title" />}
                size={TagSizeEnum.md}
                className={`mb-[-1.5rem] mt-[0.5rem] hover:opacity-100 ${
                  filterByTitle ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => setFilterByTitle(!filterByTitle)}
              />
            </>
          )}

          <FavoriteContent
            displayedItems={displayedItems}
            videosToShow={videosToShow}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            handleShowMore={handleShowMore}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isExpanded={isExpanded}
            handleBackToTop={handleBackToTop}
            scrollableParent={scrollableParent}
          />
        </div>
      )}
      <RiskyAlert
        isOpen={isOpen}
        areYouSureText={message}
        handleYes={onConfirm}
        handleClose={closeAlert}
      />
    </>
  );
};

export default FavoritePlayList;
