import { useState } from "react";

export const useRiskyAlert = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [onConfirm, setOnConfirm] = useState<() => void>(() => {});

  const showAlert = (text: string, confirmAction: () => void) => {
    setMessage(text);
    setOnConfirm(() => confirmAction);
    setIsOpen(true);
  };

  const closeAlert = () => setIsOpen(false);

  return { isOpen, message, showAlert, closeAlert, onConfirm };
};
