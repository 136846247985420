import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import { FC, useContext } from "react";

interface AlertProps {
  isOpen: boolean;
  handleYes: () => void;
  handleClose: () => void;
  areYouSureText: string;
}

const RiskyAlert: FC<AlertProps> = ({
  isOpen,
  handleYes,
  handleClose,
  areYouSureText,
}) => {
  const { mode } = useContext(ThemeContext);

  if (!isOpen) return null;

  const handleYesClick = () => {
    handleYes();
    handleClose();
  };
  const bgStyle =
    mode === "dark"
      ? "text-accent-gray400 bg-background-bgDark100"
      : "bg-background-bgLight100 text-neutral-white";

  const boxStyle =
    mode === "dark" ? "bg-background-bgLight100" : "bg-background-bgDark000";

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-opacity-60 ${bgStyle}`}
    >
      <div className={`p-6 rounded-md shadow-md w-full max-w-md ${boxStyle}`}>
        <p>Are you sure you want to {areYouSureText}?</p>
        <div className="flex justify-evenly mt-4">
          <button
            onClick={handleYesClick}
            className="flex justify-center items-center font-nunitoSans font-bold w-auto px-3 py-2 rounded-[5px] text-neutral-white bg-primary-flameRed hover:bg-opacity-80 focus:ring-offset-2 focus:ring-2 focus:ring-primary-flameRed"
          >
            YES
          </button>
          <button
            onClick={handleClose}
            className="flex justify-center items-center font-nunitoSans font-bold w-auto px-3 py-2 rounded-[5px] text-neutral-white bg-button-btnDark hover:bg-opacity-80 focus:ring-offset-2 focus:ring-2 focus:ring-button-btnDark"
          >
            NO
          </button>
        </div>
      </div>
    </div>
  );
};

export default RiskyAlert;
