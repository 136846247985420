import { FC, ReactNode, useContext } from "react";
import { YouTubeEvent, YouTubeProps } from "react-youtube";
import { Tooltip } from "react-tooltip";
import { CurrentyPlaying, SlackIcon, UsersIcon } from "@/assets/svg/svg";
import { PlayerContext } from "@/context/PlayerContext/PlayerContext";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import { VideoItem } from "@/utils/types/video-item.type";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import Heading from "@/Components/UI/Heading/Heading";
import Timer, { TimerStateEnum } from "@/Components/UI/Timer/Timer";
import LikesDislikesController from "@/Components/VideoPlayer/Queue/LikesDislikesController/LikesDislikesController";
import YoutubeVideoIframe from "../UI/YoutubeVideoIframe/YoutubeVideoIframe";
import { useRiskyAlert } from "@/utils/hooks/useRiskyAlert";
import RiskyAlert from "@/Components/UI/RiskyAlert/RiskyAlert";

interface CurrentVideoProps {
  currentVideo: VideoItem;
  userCount: number;
  timerState: TimerStateEnum;
  opts: YouTubeProps["opts"];
  playerRef: any;
  onReady: (event: YouTubeEvent) => void;
  onStateChange: (event: YouTubeEvent) => void;
  onEnd: () => void;
  onSlackClick: () => void;
  handleLike: (video: VideoItem) => void;
  handleDislike: (video: VideoItem) => void;
  children: ReactNode;
}

const CurrentVideo: FC<CurrentVideoProps> = ({
  currentVideo,
  userCount,
  timerState,
  opts,
  playerRef,
  onReady,
  onStateChange,
  onEnd,
  onSlackClick,
  handleLike,
  handleDislike,
  children,
}) => {
  const { isPlayerVisible } = useContext(PlayerContext)!;
  const { mode } = useContext(ThemeContext);
  const { isOpen, message, showAlert, closeAlert, onConfirm } = useRiskyAlert();

  return (
    <div
      className={`flex flex-col md:flex-row items-start gap-4 md:items-center ${
        isPlayerVisible ? "md:gap-9" : "md:gap-0"
      }`}
    >
      {currentVideo && (
        <YoutubeVideoIframe
          video={currentVideo}
          opts={opts}
          playerRef={playerRef}
          onReady={onReady}
          onStateChange={onStateChange}
          onEnd={onEnd}
        />
      )}
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2 md:gap-4">
          <div className="flex gap-2 items-center">
            <div
              className="flex gap-1 items-center relative"
              data-tooltip-id={`queue_UsersIconId`}
            >
              <UsersIcon hover={false} width="17" height="11" />
              <Paragraph type={ParagraphTypeEnum.p3_Default}>
                {userCount}
              </Paragraph>
              <div className="z-10">
                <Tooltip id={`queue_UsersIconId`} content={"Total users"} />
              </div>
            </div>
            <div
              className={`${
                mode === "dark" ? "bg-neutral-white" : "bg-accent-gray400"
              } hidden md:block w-2 h-2  rounded-full mr-1`}
            ></div>
            <CurrentyPlaying />
            <Paragraph type={ParagraphTypeEnum.p3_Default}>
              CURRENTLY PLAYING
            </Paragraph>
            <div
              className={`${
                mode === "dark" ? "bg-neutral-white" : "bg-accent-gray400"
              } hidden md:block w-2 h-2  rounded-full mr-1`}
            ></div>
            <div
              onClick={() =>
                showAlert("share this song on slack", onSlackClick)
              }
            >
              <SlackIcon
                tooltipText={"Share via Slack"}
                tooltipId={"Share via Slack"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="relative">
              <Heading type={HeadingTypeEnum.h1_Small} className="">
                {currentVideo?.title}
              </Heading>
            </div>

            <div className="flex flex-col md:flex-row items-start md:items-center gap-0 md:gap-2">
              <Paragraph type={ParagraphTypeEnum.p1_Small}>
                <Timer playerRef={playerRef} timerState={timerState} /> /{" "}
                {currentVideo?.duration}
              </Paragraph>
              {currentVideo && (
                <LikesDislikesController
                  currentVideo={currentVideo}
                  handleLike={handleLike}
                  handleDislike={handleDislike}
                />
              )}
              <div
                className={`${
                  mode === "dark" ? "bg-neutral-white" : "bg-accent-gray400"
                } hidden md:block w-2 h-2  rounded-full`}
              ></div>
              <Paragraph type={ParagraphTypeEnum.p1_Small}>
                Added by {currentVideo?.added}
              </Paragraph>
            </div>
          </div>
        </div>
        {children}
      </div>
      <RiskyAlert
        isOpen={isOpen}
        areYouSureText={message}
        handleYes={onConfirm}
        handleClose={closeAlert}
      />
    </div>
  );
};

export default CurrentVideo;
